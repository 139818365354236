import * as Sentry from "@sentry/nuxt";
import { storeToRefs } from "pinia";
import { useCheckout } from "~/composables/useCheckout";
import { type StripeMetadata, StripePaymentMethod } from "~/types/stripe";
import { createQueryParameters } from "~/utils/queryParams";
import { extractPaymentMethodFromSetupIntent } from "~/utils/stripe";
import { useStripeServerApi } from "~/server/composables/useStripeServerApi";
import { useTrialDaysValidator } from "~/server/composables/useTrialDaysValidator";
import { getServerLogger } from "~/utils/serverLogger";
import { useRedirectAfterPurchase } from "~/composables/useRedirectAfterPurchase";
import { useServerPurchaseTracker } from "~/composables/useServerPurchaseTracker";
import { useSwitchedStoreRepopulator } from "~/composables/useSwitchedStoreRepoulator";

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) return;

  const { $pinia, $i18n } = useNuxtApp();

  const authStore = useAuthStore($pinia);
  const discountStore = useDiscountStore($pinia);
  const checkoutStore = useCheckoutStore($pinia);
  const { register } = useRegistration();
  const checkout = useCheckout();
  const onboardingStore = useOnboardingStore($pinia);
  const { registrationParams } = storeToRefs(onboardingStore);
  const { getSetupIntent, createSubscription, findActiveSubscriptionOfCustomer, getOrCreateSubscriptionPlan } =
    useStripeServerApi();
  const validateTrialDays = useTrialDaysValidator();

  const { redirectSuccess, redirectFail } = useRedirectAfterPurchase();

  const error = useError();

  const queryParameters = createQueryParameters(to.query);

  const { isCountryWithTaxRequirements } = useConditions();
  const featureFlags = useFeatureFlags();

  if (queryParameters.has("redirect_status") && !checkout.checkoutParameters) {
    Sentry.captureMessage("Checkout parameter object is missing during Stripe redirect", {
      extra: {
        requestQueryParams: queryParameters.getAll(),
        authStore: authStore ?? "undefined",
        discountStore: discountStore ?? "undefined",
      },
    });
  }

  if (
    !checkout.checkoutParameters ||
    !queryParameters.hasAll(["redirect_status", "setup_intent", "period", "currency", "sku", "amount"])
  ) {
    return;
  }

  const setupIntent = await getSetupIntent(queryParameters.get("setup_intent"));

  const paymentMethodName =
    typeof setupIntent.latest_attempt === "object"
      ? (setupIntent.latest_attempt?.payment_method_details?.type ?? "unknown")
      : "unknonw";

  const switchedSession = checkoutStore.browserRandomId !== setupIntent.metadata?.browser_random_id;

  const stripeMetaData = {
    ...setupIntent.metadata,
    payment_method_name: paymentMethodName,
    switched_session: switchedSession ? "true" : "false",
  } as StripeMetadata;

  const userUUID = stripeMetaData.useruuid;
  const sessionID = stripeMetaData.sessionId;

  const log = getServerLogger("handleCheckoutResult", { uuid: userUUID, sID: sessionID });

  log(`Got setup intent (${queryParameters.get("setup_intent")})`, setupIntent);

  const customer =
    typeof setupIntent.customer === "object" && setupIntent.customer && !setupIntent.customer.deleted
      ? setupIntent.customer
      : null;

  const customerId = customer?.id;
  const customerEmail = customer?.email;
  const incomplete = stripeMetaData.incomplete === "true";
  const skipped = stripeMetaData.skipped_onboarding === "true";
  const discountCode = stripeMetaData.used_code ?? "";

  if (switchedSession) {
    log("switched browser session detected!");
    const repopulate = useSwitchedStoreRepopulator();

    repopulate(
      sessionID,
      discountCode,
      {
        store_snapshot_awin: stripeMetaData.store_snapshot_awin,
        store_snapshot_iterable: stripeMetaData.store_snapshot_iterable,
        store_snapshot_meta: stripeMetaData.store_snapshot_meta,
      },
      {
        utm_medium: stripeMetaData.utm_medium,
        utm_source: stripeMetaData.utm_source,
        utm_campaign: stripeMetaData.utm_campaign,
        utm_term: stripeMetaData.utm_term,
        utm_content: stripeMetaData.utm_content,
      },
    );
  }

  if (!customerId || !customerEmail) {
    log(`Customer Data Missing`, { customerEmail, customerId });
    return redirectFail(skipped, incomplete);
  }

  if (queryParameters.get("redirect_status") === "failed" || error.value) {
    log(`Stripe redirect failed due to error`, {
      error: error.value,
      stripeError:
        queryParameters.get("redirect_status") === "failed" && setupIntent.last_setup_error
          ? { type: setupIntent.last_setup_error.type, code: setupIntent.last_setup_error.code }
          : undefined,
    });

    if (error.value) {
      Sentry.captureMessage("Stripe redirect failed due to error", {
        extra: {
          error: error.value,
          requestQueryParams: queryParameters.getAll(),
          authStore: authStore ?? "undefined",
          discountStore: discountStore ?? "undefined",
          userType: stripeMetaData.user_type ?? "undefined",
        },
      });
    }

    return redirectFail(skipped, incomplete);
  }

  log(`Handling checkout result`, {
    stripeMetaData,
    queryParameters: queryParameters.getAll(),
    requestQueryParams: queryParameters.getAll(),
    authStore: authStore ?? "undefined",
    discountStore: discountStore ?? "undefined",
    userType: stripeMetaData.user_type ?? "undefined",
  });

  if (!stripeMetaData?.country) {
    log(`Missing country in setup intent metadata`);

    throw createError({ message: "Invalid setup intent: missing country" });
  }

  const existingSubscription = await findActiveSubscriptionOfCustomer(setupIntent.customer);

  if (existingSubscription) {
    log(`Subscription (${existingSubscription.id}) already exists`);

    if (stripeMetaData.user_type === "guest") {
      log(`Logs user in`, { email: customerEmail });
      registrationParams.value.email = customerEmail;
      registrationParams.value.password = stripeMetaData.initial_password;
      onboardingStore.$persist();
      await authStore.login(customerEmail, stripeMetaData.initial_password);
    }

    return redirectSuccess(stripeMetaData.user_type, incomplete, paymentMethodName, userUUID, sessionID);
  }

  if (stripeMetaData.user_type === "guest") {
    log(`Register user in backend`, { email: customerEmail });

    await register(stripeMetaData.initial_password, customerEmail, userUUID);
    registrationParams.value.email = customerEmail;
    registrationParams.value.password = stripeMetaData.initial_password;
    onboardingStore.$persist();
    await authStore.login(customerEmail, stripeMetaData.initial_password);
  }

  const transactionId = `${queryParameters.get("period")}_STRIPE_${uuidv4()}`;

  const segment = await discountStore.getActiveSegment();

  const paymentMethodId = extractPaymentMethodFromSetupIntent(setupIntent);
  log(`Extracted payment method: "${paymentMethodId}"`);

  const sku = queryParameters.get("sku");

  const plan = await getOrCreateSubscriptionPlan(sku, stripeMetaData.country, $i18n.locale.value, segment);

  const trialDays = validateTrialDays(parseInt(stripeMetaData.trial_days), plan.interval_count);

  const subscription = await createSubscription({
    customer_id: customerId,
    sku,
    trial_days: trialDays,
    transaction_id: transactionId,
    payment_method: paymentMethodId,
    payment_method_types: (setupIntent.payment_method_types as StripePaymentMethod[]).filter(
      (method) => ![StripePaymentMethod.BANCONTACT, StripePaymentMethod.IDEAL].includes(method),
    ),
    stripe_metadata: stripeMetaData,
    autoTax: isCountryWithTaxRequirements.value && featureFlags.tax_postalcode.isOn().value,
  });

  if (!subscription) {
    log(`Subscription creation failed`);
    return redirectFail(skipped, incomplete);
  }

  log(`Creating subscription done`, { subscription });

  const trackServerPurchase = useServerPurchaseTracker(userUUID, sessionID);

  await trackServerPurchase(
    customerEmail,
    queryParameters.get("period"),
    queryParameters.get("currency"),
    sku,
    parseInt(queryParameters.get("amount")),
    discountCode,
    transactionId,
    trialDays,
    switchedSession,
    paymentMethodName,
  );

  return redirectSuccess(stripeMetaData.user_type, incomplete, paymentMethodName, userUUID, sessionID);
});
